<template>
	<div class="All-footer">
		<div class="info-tab">
			<div class="info-tab-img">
				<img src="@/assets/All-footer-logo.png" alt="" />
			</div>
			<div class="info-tab-form">
				<input type="text" placeholder="姓名" />
				<input type="text" placeholder="公司">
				<input type="text" placeholder="手机">
				<input type="text" placeholder="邮箱">
				<input type="text" placeholder="内容留言">
				<div class="submit" @click="submit">提交</div>
			</div>
		</div>
		<div class="footer-cont">
			<div class="footer-cont-contact">
				<h1>联系我们</h1>
				<p>电话：18684987690</p>
				<p>邮箱：278495689@qq.com</p>
				<p>地址：湖南省长沙县铭诚·绿谷智慧产业园(人民东路二段北) 长沙县人民东路二段169号</p>
			</div>
			<div class="footer-cont-honor">
				<h1>资质荣誉</h1>
				<p v-for="(item,index) in honorArr" :key="index" @click="jump(item.path)">{{item.name}}</p>
			</div>
			<div class="footer-cont-business">
				<h1>业务领域</h1>
				<p v-for="(item,index) in businessArr" :key="index" @click="toPath(item.path)">{{item.name}}</p>
			</div>
			<div class="footer-cont-performance">
				<h1>参与业绩</h1>
				<p>采样机系列</p>
				<p>采样机系列</p>
				<p>采样机系列</p>
				<p>采样机系列</p>
				<p>采样机系列</p>
				<p>采样机系列</p>
			</div>
		</div>
	</div>
</template>


<script>
	import axios from 'axios';
	// import nodemailer from 'nodemailer';
	/*let transporter = nodemailer.createTransport({
	  host: 'smtp.qq.com',
	  port: 465,
	  secure: true,
	  auth: {
	    user: '**********',
	    pass: 'your-password'
	  }
	}); */

	export default {
		name: 'HelloWorld',
		props: {

		},
		data() {
			return {
				honorArr: [{
					name: "营业执照",
					path: "http://www.hunandajun.top/img/zzry-img2.74a4c097.png"
				}, {
					name: "湖南省高新技术企业",
					path: "http://www.hunandajun.top/img/zzry-img1.a38d1659.png"
				}, {
					name: "IS09001 质量管理体系认证",
					path: "http://www.hunandajun.top/img/zzry-img3.1ceeaf08.png"
				}, {
					name: "IS014001 质量管理体系认证",
					path: "http://www.hunandajun.top/img/zzry-img4.98ace454.png"
				}, {
					name: "OHSAS 职业健康安全管理体系认证",
					path: "http://www.hunandajun.top/img/zzry-img5.6a81cdef.png"
				}],
				businessArr: [{
						name: '松动机系列',
						path: "/businessArea/4"
					},
					{
						name: '双轴式混合机系列',
						path: "/businessArea/6"
					},
					{
						name: '智能型样品封装系统',
						path: "/businessArea/10"
					},
					{
						name: '清算破碎机系列',
						path: "/businessArea/8"
					},
					{
						name: '火车采样机监控系统',
						path: "/businessArea/9"
					},
					{
						name: '板式给矿机',
						path: "/businessArea/2"
					},
					{
						name: '重型板式给料机',
						path: "/businessArea/2"
					}
				]
			}
		},
		methods: {
			jump(path) {
				window.open(path, '_blank')
			},
			toPath(path) {
				this.$router.replace(path)
				document.documentElement.scrollTop = 0;
			},
			submit() {
				/* 	const transporter = nodemailer.createTransport({
						host: 'smtp.example.com',
						port: 587,
						secure: false,
						auth: {
							user: '2803620961@qq.com',
							pass: 'vncxzlasomhvdgge'
						}
					}); */

				// var data = decodeURIComponent(this.email.toString());
				// var decode_email = window.atob(data);

				Email.send({
					Host: "http://www.hunandajun.top/", //邮箱地址
					Username: "2803620961@qq.com",
					Password: "vncxzlasomhvdgge", //授权码
					To: '2992522219@qq.com', //接收邮件的地址
					From: "2803620961@qq.com", //发	送邮箱的地址
					Subject: "asfasf合规确认消息提醒", //邮件主题
					Body: "1234647" //邮件内容
				}).then(() => {
					console.log('发送成功！'); //发送成功提示
				});


				// 设置API密钥和电子邮件数据
				/* const api_key = 'vncxzlasomhvdgge';
				const domain = '2803620961@qq.com';
				const data = {
					from: '2992522219@qq.com',
					to: '2803620961@qq.com',
					subject: 'Hello from Vue.js!',
					text: 'This is a test email sent from Vue.js.'
				};

				// 使用Mailgun API发送电子邮件
				axios.post(`https://api.mailgun.net/v3/${domain}/messages`, data, {
						auth: {
							username: 'api',
							password: api_key
						}
					})
					.then(response => {
						console.log('Email sent:', response.data);
					})
					.catch(error => {
						console.log('Error:', error.response.data);
					}); */


			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.All-footer {
		background-color: #137AC2;
		width: calc(100% - 720px);
		padding: 0 360px;
		padding-top: 33px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.info-tab {
			width: 1200px;
			padding-bottom: 27px;
			border-bottom: 2px solid #5AA2D4;
			display: flex;
			align-items: center;

			.info-tab-img {
				width: 290px;
				height: 210px;
				min-width: 290px;
				margin-right: 50px;

				img {
					width: 100%;
					height: 100%;
				}

				padding-right: 28px;
				border-right: 2px solid #5AA2D4;
			}

			.info-tab-form {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				user-select: none;

				input {
					width: calc(200px - 40px);
					height: 33px;
					line-height: 33px;
					background-color: #fff;
					border: none;
					margin-bottom: 18px;
					border-radius: 500px;
					padding: 0 20px;
					outline: none;
					font-size: 18px;
				}

				input:nth-child(5) {
					width: 580px;
				}

				.submit {
					width: 200px;
					height: 33px;
					text-align: center;
					background-color: #086AAF;
					line-height: 33px;
					border-radius: 500px;
					color: #fff;
					font-weight: bold;
					font-size: 16px;
					cursor: pointer;
				}
			}
		}

		.footer-cont {
			display: flex;
			width: 1200px;
			justify-content: space-evenly;
			margin: 30px 0;

			>div {
				width: 20%;
				// margin: auto;

				h1 {
					font-weight: bold;
					font-size: 24px;
					color: #fff;
					margin-bottom: 15px;
				}

				p {
					font-size: 20px;
					color: #67C1F2;
					cursor: pointer;
					margin-bottom: 11px;

				}
			}

			.footer-cont-contact {
				width: 22%;
			}

			.footer-cont-honor {
				width: 27%;
				// margin-left: 10%;
			}

			.footer-cont-business {}

			.footer-cont-performance {
				visibility: hidden;
			}
		}
	}
</style>